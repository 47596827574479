import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { reorderClinician } from '^/contacts/persons/patients/actions';
import { ContactResponse } from '^/contacts/types';

interface Props {
  practiceId: string;
  clinician: ContactResponse;
  cliniciansCount: number;
}

export type ReorderClinicianProps = ConnectedProps<typeof connector> & Props;

class ReorderClinician extends React.PureComponent<ReorderClinicianProps> {
  public moveItem = (clinicianId: string, position: number) => {
    const { practiceId } = this.props;

    this.props.reorderClinician(practiceId, clinicianId, {
      position,
    });
  };

  public render() {
    const { cliniciansCount, clinician } = this.props;

    return (
      <tr key={clinician.id}>
        <td>
          <button
            disabled={clinician.position === 0}
            onClick={this.moveItem.bind(
              this,
              clinician.id,
              clinician.position - 1
            )}
          >
            <FontAwesomeIcon icon="chevron-up" />
          </button>
        </td>
        <td>
          <button
            disabled={clinician.position === cliniciansCount - 1}
            onClick={this.moveItem.bind(
              this,
              clinician.id,
              clinician.position + 1
            )}
          >
            <FontAwesomeIcon icon="chevron-down" />
          </button>
        </td>
        <td>{clinician.position}</td>
        <td>{clinician.name}</td>
      </tr>
    );
  }
}

export { ReorderClinician as TestableReorderClinician };

const connector = connect(undefined, { reorderClinician });

export default connector(ReorderClinician);
