import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import PageSubSection from '^/common/page-section/page-sub-section';
import { getDentistsByPractice } from '^/contacts/persons/patients/actions';
import { ContactResponse } from '^/contacts/types';
import { StoreState } from '^/types';
import ReorderClinician from './reorder-clinician';

interface Props {
  practiceId: string;
}

export type ReorderCliniciansProps = Props & ConnectedProps<typeof connector>;

class ReorderClinicians extends React.PureComponent<ReorderCliniciansProps> {
  public componentDidMount() {
    const { practiceId } = this.props;
    this.props.getDentistsByPractice(practiceId);
  }

  public render() {
    const { practiceId } = this.props;
    const clinicians = this.props.clinicians[practiceId];

    if (clinicians && clinicians.length > 0) {
      const sortedClinicians = clinicians as ContactResponse[];
      sortedClinicians.sort((a, b) => a.position - b.position);

      return (
        <PageSubSection heading="Reorder Clinicians">
          <p>
            The order displayed here will be the order these Clinicians appear
            on your iPlan portal
          </p>
          <table>
            <thead>
              <tr>
                <th />
                <th />
                <th />
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {sortedClinicians.map(clinician => {
                return (
                  <ReorderClinician
                    key={clinician.id}
                    practiceId={practiceId}
                    clinician={clinician}
                    cliniciansCount={sortedClinicians.length}
                  />
                );
              })}
            </tbody>
          </table>
        </PageSubSection>
      );
    }

    return null;
  }
}

export const mapState = (state: StoreState) => ({
  clinicians: state.dentistsByPractice,
});

const connector = connect(mapState, {
  getDentistsByPractice,
});

export { ReorderClinicians as TestableReorderClinicians };

export default connector(ReorderClinicians);
