import { Container } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import FormCustomisation from '^/common/form-setup/form-customisation';
import ReorderClinicians from '^/common/form-setup/reorder-clinicians';
import ReorderPlans from '^/common/form-setup/reorder-plans';
import { getContactName } from '^/common/helper-functions';
import PageSection from '^/common/page-section/page-section';
import { getContact } from '^/contacts/actions';
import BreadcrumbBar from '^/navigation/breadcrumb-bar';
import PageContent from '^/page-container/page-content';
import { StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';

export type FormSetupPageProps = ConnectedProps<typeof connector>;

class FormSetupPage extends React.PureComponent<FormSetupPageProps> {
  public componentDidMount(): void {
    const { contactId } = this.props;
    if (contactId) {
      this.props.getContact(contactId, { isPractice: true });
    }
  }

  public componentDidUpdate(prevProps: FormSetupPageProps) {
    const { contactId } = this.props;

    if (contactId !== prevProps.contactId && contactId) {
      this.props.getContact(contactId, { isPractice: true });
    }
  }

  public render() {
    const { contactId, practice } = this.props;
    return (
      <PageContent page="form-setup">
        <Container>
          <PageSection>
            <BreadcrumbBar
              items={[
                ['Form Setup', '/form-setup'],
                !practice ? 'Loading...' : getContactName(practice),
              ]}
            />
            {contactId && practice && (
              <>
                <FormCustomisation contactId={contactId} practice={practice} />
                <ReorderClinicians practiceId={contactId} />
                <ReorderPlans practiceId={contactId} />
              </>
            )}
          </PageSection>
        </Container>
      </PageContent>
    );
  }
}

export { FormSetupPage as TestableFormSetupPage };

export const mapState = (state: StoreState) => ({
  practice:
    state.iplan &&
    getItemFromCache(state.iplan.selectedPracticeId, state.contactsCache),
  contactId: state.iplan?.selectedPracticeId,
});

const connector = connect(mapState, { getContact });

export default connector(FormSetupPage);
