import { FormGroup } from '@dabapps/roe';
import React from 'react';
import { Field, Form, InjectedFormProps, reduxForm } from 'redux-form';

import AppButton from '^/common/app-button';
import RenderInputField from '^/form-helpers/render-input-field';
import { Plan } from '^/plans/types';

interface OwnProps {
  onCancel(): void;
}

export type EditNicknameFormProps = OwnProps &
  InjectedFormProps<Partial<Plan>, OwnProps>;

export class EditNicknameForm extends React.Component<EditNicknameFormProps> {
  public render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Field name="nickname" component={RenderInputField} type="text" />
        </FormGroup>
        <div className="form-buttons">
          <AppButton onClick={this.props.onCancel} link>
            cancel
          </AppButton>
          <AppButton type="submit" link>
            update
          </AppButton>
        </div>
      </Form>
    );
  }
}

export { EditNicknameForm as TestableEditNicknameForm };

export default reduxForm<Partial<Plan>, OwnProps>({})(EditNicknameForm);
