import { Bank } from '^/contacts/ledger/types';
import { Fee, FeeResponse } from '^/fees/types';

export interface Plan {
  readonly id: string;
  readonly created?: string;
  readonly modified?: string;
  status?: PlanStatus;
  code: string;
  description: string;
  nickname?: string;
  plan_length_months?: number;
  practice: string;
  template?: string | null;
  continuous?: boolean;
  lapse_trigger_months?: number;
  payment_interval_months?: 1;
  fee?: Fee;
  joining_fee?: Fee;
  admin_fee?: Fee | null;
  variable_pricing?: boolean;
  readonly template_overrides_plan?: boolean;
  insprac: boolean;
  is_membership: boolean;
  associated_bank: Bank;
  age_restriction?: AgeRestriction;
  available_for_signup?: boolean;
  is_billing_container: boolean;
  position: number;
  subscription_fee: number;
}

export type PlanResponse = Readonly<Required<Plan>>;

export enum PlanStatus {
  Live = 'LIVE',
  Archived = 'ARCHIVED',
}

export enum AgeRestriction {
  Child = 'Child',
  Adult = 'Adult',
  Mixed = 'Mixed',
}

export type DiscountResponse = Readonly<{
  id: string;
  created: string;
  modified: string;
  description: string;
  group_quantity: number;
  discount: number;
  plan: string;
}>;

export type Discount = Readonly<{
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  description: string;
  group_quantity: number;
  discount: number;
  plan: string;
}>;

export type FeesByAgeResponse = Readonly<{
  id: string;
  created: string;
  modified: string;
  age_from: number;
  age_to: number;
  payment_amount_monthly: FeeResponse;
  payment_amount_annually: FeeResponse;
  admin_fee_monthly: FeeResponse;
  admin_fee_annually: FeeResponse;
  joining_fee: FeeResponse;
  plan: string;
}>;

export interface FeesByAge {
  readonly id?: string;
  readonly created?: string;
  readonly modified?: string;
  age_from: number;
  age_to: number;
  payment_amount_monthly: Fee;
  payment_amount_annually: Fee;
  admin_fee_monthly: Fee;
  admin_fee_annually: Fee;
  joining_fee: Fee;
  plan: string;
}
