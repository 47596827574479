import { Template } from '^/admin/templates/types';
import { Fee } from '^/fees/types';
import { MembershipSubscription } from '^/memberships/subscriptions/types';
import { Membership } from '^/memberships/types';
import { PlanSubscription } from '^/plans/subscriptions/types';
import { PublicPlan, PublicPlanList } from '^/public/types';
import { Plan } from './types';

export function formatFeeAmount(
  fee?: Fee | number | null,
  currency = '£',
  defaultValue = '-',
  flip: boolean = false
) {
  if (typeof fee === 'number') {
    const flipped = flip ? -fee : fee;
    return `${currency}${flipped.toFixed(2)}`;
  }

  if (fee && typeof fee.amount === 'number') {
    const flipped = flip ? -fee.amount : fee.amount;

    return `${currency}${flipped.toFixed(2)}`;
  }

  return defaultValue;
}

export function getPlanName(
  plan?: Plan | Template | Membership | null,
  short?: boolean,
  defaultValue: string = '-'
) {
  if (!plan) {
    return defaultValue;
  }

  if (short || plan.code === plan.description || !plan.description) {
    return plan.code;
  }

  return `${plan.code} (${plan.description})`;
}

export function getSubscriptionName(
  subscription?: MembershipSubscription | PlanSubscription | null,
  short?: boolean,
  defaultValue: string = '-'
) {
  if (!subscription) {
    return defaultValue;
  }

  if (subscription.reference) {
    return subscription.reference;
  }

  return getPlanName(subscription.plan_detail, short, defaultValue);
}

export function getSelectedPlan(
  plans: PublicPlanList | Plan[] | undefined,
  selectedPlanId: string
): Readonly<PublicPlan> | undefined {
  return plans && plans.filter(item => item.id === selectedPlanId)[0];
}
