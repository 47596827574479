import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

import AllowRoute from '^/common/allow-route';
import FormSetupPage from '^/iplan/form-setup/form-setup-page';
import PageContainer from '^/iplan/page-container';
import PatientsPage from '^/iplan/patients/patients-routes-page';
import PracticeDetailsPage from '^/iplan/practice-details/practice-details-page';

export const IPlanUserRoutes = () => {
  return (
    <PageContainer>
      <Switch>
        <AllowRoute exact path="/">
          {<Redirect to="/patients" />}
        </AllowRoute>

        <AllowRoute path="/patients" component={PatientsPage} />
        <AllowRoute
          exact
          path="/practice-details"
          component={PracticeDetailsPage}
        />
        <AllowRoute exact path="/form-setup" component={FormSetupPage} />
      </Switch>
    </PageContainer>
  );
};
