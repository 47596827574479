import { Column, Container, Row } from '@dabapps/roe';
import classNames from 'classnames';
import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { connect, ConnectedProps } from 'react-redux';
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from 'redux-form';

import AppButton from '^/common/app-button';
import ColorPicker from '^/common/color-picker';
import { saveContact } from '^/contacts/actions';
import { CompanyProfileResponse } from '^/contacts/companies/types';
import { Contact, ContactResponse } from '^/contacts/types';
import RenderSingleButtonFileUpload from '^/form-helpers/render-single-file-button-upload';
import { PUBLIC_MAX_CUSTOM_TEXT_LENGTH } from '^/public/constants';
import { CachedItem, StoreState } from '^/types';

const FORM_NAME = 'FormCustomisationOptionsForm';

const selector = formValueSelector(FORM_NAME);

interface OwnProps {
  contactId: string;
  practice: CachedItem<ContactResponse>;
}

export interface FormCustomisationOptionsValues {
  primary_colour: string;
  secondary_colour: string;
  custom_text_form: string;
  custom_text_landing: string;
  logo_url: string;
}

export const mapState = (state: StoreState) => ({
  primary_colour: selector(state, 'primary_colour'),
  secondary_colour: selector(state, 'secondary_colour'),
  custom_text_form: selector(state, 'custom_text_form'),
  custom_text_landing: selector(state, 'custom_text_landing'),
  unique_practice_url: selector(state, 'unique_practice_url'),
  logo_url: selector(state, 'logo_url'),
});

const connector = connect(mapState, {
  saveContact,
});

export type FormCustomisationOptionsFormProps = InjectedFormProps<
  FormCustomisationOptionsValues
> &
  OwnProps &
  ConnectedProps<typeof connector>;

class FormCustomisationOptions extends React.PureComponent<
  FormCustomisationOptionsFormProps
> {
  public componentDidMount(): void {
    const { practice } = this.props;
    const company = practice?.company;

    if (!company) {
      return;
    }

    this.updateFieldValuesFromCompanyDetails(company);
  }

  public updateFieldValuesFromCompanyDetails = (
    company: CompanyProfileResponse
  ) => {
    if (company.primary_colour !== '') {
      this.props.change('primary_colour', company.primary_colour);
    }

    if (company.secondary_colour !== '') {
      this.props.change('secondary_colour', company.secondary_colour);
    }

    this.props.change('custom_text_form', company.custom_text_form);
    this.props.change('custom_text_landing', company.custom_text_landing);
    this.props.change('unique_practice_url', company.unique_practice_url);
    this.props.change('logo_url', company.logo_url);
  };

  public onFormSubmitEventHandler = async (
    formData: FormCustomisationOptionsValues
  ) => {
    const { contactId, practice } = this.props;

    if (practice) {
      const requestPayload: Contact = Object.assign({}, practice, {
        company: {
          ...practice.company,
          ...formData,
        },
      });

      const updatedContact = await this.props.saveContact(
        contactId,
        requestPayload
      );

      if (updatedContact) {
        const {
          data: { company },
        } = updatedContact;

        if (company) {
          this.updateFieldValuesFromCompanyDetails(company);
        }
      }
    }
  };

  public onAttachmentUploaded = (attachmentURL: string) =>
    this.props.change('logo_url', attachmentURL);

  public render() {
    const { contactId, handleSubmit, submitting } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.onFormSubmitEventHandler)}>
        <Row className="form-setup">
          <Container>
            <Column xs={12} md={4}>
              <Column>
                <h1 className="title">Unique Practice URL</h1>
                <Field
                  type="text"
                  className="unique-practice-url"
                  name="unique_practice_url"
                  component="input"
                  disabled
                />
              </Column>
              <Column>
                <Column>
                  <h1 className="title">Practice Logo</h1>
                  <img
                    className={classNames('practice-logo', {
                      'default-logo': !this.props.logo_url,
                    })}
                    src={this.props.logo_url}
                  />
                </Column>
                <Column>
                  <Field
                    allowedFileTypes={'.PNG, .JPG, .JPEG, .BMP'}
                    component={RenderSingleButtonFileUpload}
                    name="logo"
                    buttonText="Upload"
                    contactId={contactId}
                    onAttachmentUploaded={this.onAttachmentUploaded}
                  />
                </Column>
              </Column>
            </Column>
            <Column xs={12} md={4}>
              <h1 className="title">Custom Colours</h1>
              <Column xs={12} md={6}>
                <Field
                  name="primary_colour"
                  defaultValue="#484E61"
                  component={ColorPicker}
                />
              </Column>
              <Column xs={12} md={6}>
                <Field
                  name="secondary_colour"
                  defaultValue="#b6c0d2"
                  component={ColorPicker}
                />
              </Column>
              <Column xs={12}>
                <h1 className="title">Landing Page Custom Text</h1>
                <Field
                  name="custom_text_landing"
                  component="textarea"
                  className="custom-text"
                  maxLength={PUBLIC_MAX_CUSTOM_TEXT_LENGTH}
                />
              </Column>
              <Column xs={12}>
                <h1 className="title">Form Custom Text</h1>
                <Field
                  name="custom_text_form"
                  component="textarea"
                  className="custom-text"
                  maxLength={PUBLIC_MAX_CUSTOM_TEXT_LENGTH}
                />
              </Column>
            </Column>
            <Column xs={12} md={4}>
              <h1 className="title">QR Code</h1>
              <QRCode value={this.props.unique_practice_url} />
              <AppButton type="submit" loading={submitting} primary>
                Save
              </AppButton>
            </Column>
          </Container>
        </Row>
      </Form>
    );
  }
}

export { FormCustomisationOptions as TestableFormCustomisationOptions };

export default reduxForm<FormCustomisationOptionsValues, OwnProps>({
  form: FORM_NAME,
  enableReinitialize: true,
})(connector(FormCustomisationOptions));
