import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import PageSubSection from '^/common/page-section/page-sub-section';
import { getContactPlans } from '^/plans/actions';
import { StoreState } from '^/types';
import { getCurrentPage } from '^/utils/pagination-helpers';
import ReorderPlan from './reorder-plan';

interface Props {
  practiceId: string;
}

export type ReorderPlansProps = Props & ConnectedProps<typeof connector>;

class ReorderPlans extends React.PureComponent<ReorderPlansProps> {
  public componentDidMount() {
    const { practiceId } = this.props;
    this.props.getContactPlans(practiceId, 1, 90, '', false, false);
  }

  public render() {
    const { practiceId } = this.props;
    const plans =
      this.props.plans[practiceId] &&
      getCurrentPage(this.props.plans[practiceId]);

    return plans ? (
      <PageSubSection heading="Reorder Plans">
        <p>
          The order displayed here will be the order these Plans appear on your
          iPlan portal
        </p>
        <table>
          <thead>
            <tr>
              <th />
              <th />
              <th />
              <th>Code</th>
              <th>Description</th>
              <th>Nickname</th>
            </tr>
          </thead>
          <tbody>
            {plans.map(plan => {
              return (
                <ReorderPlan
                  key={plan.id}
                  practiceId={practiceId}
                  plan={plan}
                  plansCount={plans.length}
                />
              );
            })}
          </tbody>
        </table>
      </PageSubSection>
    ) : null;
  }
}

export const mapState = (state: StoreState) => ({
  plans: state.contactPlans,
});

const connector = connect(mapState, { getContactPlans });

export { ReorderPlans as TestableReorderPlans };

export default connector(ReorderPlans);
