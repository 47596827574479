import { anyPending } from '@dabapps/redux-requests';
import { Column, Row } from '@dabapps/roe';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import {
  DELETE_ADDRESS,
  GET_CONTACT_ADDRESSES,
  SAVE_ADDRESS,
  SET_MAILING_ADDRESS,
} from '^/address/actions';
import Addresses from '^/address/addresses-component';
import PageSection from '^/common/page-section/page-section';
import PageSubSection from '^/common/page-section/page-sub-section';
import { GET_CONTACT, SAVE_CONTACT } from '^/contacts/actions';
import CompaniesDetailsCard from '^/contacts/companies/details-card';
import { ContactStatus } from '^/contacts/types';
import { paymentDetailActions } from '^/payment-details/actions';
import PlanAddButton from '^/plans/add-button';
import PlansTable from '^/plans/table';
import { relationshipActions } from '^/relationships/actions';
import RelationshipsDetailCard from '^/relationships/detail-card';
import RelationshipsListCard from '^/relationships/list-card';
import { RelationshipType } from '^/relationships/types';
import { ContactType, StoreState } from '^/types';
import { getItemFromCache } from '^/utils/cache-helpers';
import CliniciansCard from './iplan-clinicians-card';

export type PracticeDetailsProps = ConnectedProps<typeof connector>;

const PracticeDetails = ({
  contact,
  contactId,
  loading,
}: PracticeDetailsProps) => {
  const disabled = contact?.status === ContactStatus.Archived;

  if (contactId && contact) {
    return (
      <Row>
        <Column xs={12} md={7} lg={8}>
          <PageSection className="iplan">
            <PageSubSection
              heading="Practice Details"
              loading={loading.details}
            >
              <CompaniesDetailsCard
                allowEdit={false}
                labelPrefix="Practice"
                companyId={contactId}
                disabled={disabled}
              />
            </PageSubSection>
            <RelationshipsListCard
              allowAdd={false}
              heading="Practice Owner"
              contactId={contactId}
              filters={{ type: 'Individual,Company,Practice' }}
              label="Owner"
              showBothSides
              types={[RelationshipType.CompanyProvider]}
              detailed
              createInReverse
              disabled={disabled}
            />
            <RelationshipsDetailCard
              allowAdd={false}
              heading="Practice Contact"
              contactId={contactId}
              filters={{
                type: [ContactType.Individual, ContactType.Company].join(','),
              }}
              label="Contact"
              types={[RelationshipType.CompanyContact]}
              disabled={disabled}
            />
            <PageSubSection heading="Addresses" loading={loading.addresses}>
              <Addresses
                contactId={contactId}
                disabled={disabled}
                allowAdd={false}
                allowDelete={false}
              />
            </PageSubSection>
            <CliniciansCard contactId={contactId} />
          </PageSection>
          <PageSection heading="Plans">
            <PlansTable contactId={contactId} />
            <div className="page-section-buttons">
              <PlanAddButton contactId={contactId} disabled={disabled} />
            </div>
          </PageSection>
        </Column>
      </Row>
    );
  }
  return null;
};

export { PracticeDetails as TestablePracticeDetails };

export const mapState = (store: StoreState) => ({
  contactId: store.iplan?.selectedPracticeId,
  contact:
    store.iplan &&
    getItemFromCache(store.iplan.selectedPracticeId, store.contactsCache),
  loading: {
    details: anyPending(store.responses, [GET_CONTACT, SAVE_CONTACT]),
    addresses: anyPending(store.responses, [
      GET_CONTACT_ADDRESSES,
      SAVE_ADDRESS,
      DELETE_ADDRESS,
      SET_MAILING_ADDRESS,
    ]),
    relationships: anyPending(store.responses, relationshipActions),
    paymentDetails: anyPending(store.responses, paymentDetailActions),
  },
});

const connector = connect(mapState);

export default connector(PracticeDetails);
