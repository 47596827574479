import React, { ReactNode } from 'react';

import { formatAddress, getPrimaryAddress } from '^/address/helpers';
import HeaderBar, {
  HeaderBarStatus,
  HeaderBarStatusColour,
} from '^/common/header-bar';
import { getContactCrmId, getContactName } from '^/common/helper-functions';
import { Contact, ContactSubscriptionStatus } from '^/contacts/types';

export interface OwnProps {
  contact?: Contact;
  primaryActions?: ReactNode | ReactNode[];
  secondaryActions?: ReactNode | ReactNode[];
  loading?: boolean;
  hideSubtitle?: boolean;
}

export type ContactHeaderBarProps = OwnProps;

class ContactHeaderBar extends React.PureComponent<ContactHeaderBarProps> {
  public render() {
    const {
      contact,
      primaryActions: primaryActions,
      secondaryActions: secondaryActions,
      loading,
    } = this.props;
    const address = formatAddress(getPrimaryAddress(contact));

    return (
      <HeaderBar
        title={getContactName(contact)}
        subtitle={this.getSubtitle(contact)}
        status={this.getContactStatus(contact)}
        primaryActions={primaryActions}
        secondaryActions={secondaryActions}
        transparent
        loading={!contact && loading}
      >
        {address !== '-' ? address : null}
        {this.props.children}
      </HeaderBar>
    );
  }

  private getSubtitle(contact?: Contact): string | undefined {
    if (this.props.hideSubtitle) {
      return undefined;
    }

    if (!contact) {
      return undefined;
    }

    if (contact.category) {
      return `${contact.category} ${getContactCrmId(contact)}`;
    }

    return getContactCrmId(contact);
  }

  private getContactStatus(contact?: Contact): HeaderBarStatus | undefined {
    if (!contact || !contact.subscription_status || this.props.hideSubtitle) {
      return undefined;
    }

    switch (contact.subscription_status) {
      case ContactSubscriptionStatus.Lapsed:
        return {
          colour: HeaderBarStatusColour.Red,
          description: 'Contact has a lapsed subscription',
          title: 'Subscription lapsed',
        };
      case ContactSubscriptionStatus.Overdue:
        return {
          colour: HeaderBarStatusColour.Yellow,
          description: `Contact has an outstanding subscription of £${contact.calculated_balance}`,
          title: 'Outstanding Subscription',
        };
      case ContactSubscriptionStatus.Active:
        return {
          colour: HeaderBarStatusColour.Green,
          description:
            'Contact has an active subscription and a healthy balance',
          title: 'Subscription active',
        };
      default:
        return {
          colour: HeaderBarStatusColour.Pink,
          description: "Contact doesn't have any active subscriptions",
          title: 'No active subscriptions',
        };
    }
  }
}

export default ContactHeaderBar;
