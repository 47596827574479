import { isPending } from '@dabapps/redux-requests';
import { FormGroup } from '@dabapps/roe';
import moment from 'moment';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';

import ErrorRenderer from '^/common/error-renderer';
import PageSection from '^/common/page-section/page-section';
import { isBackdateValid, required } from '^/common/validation';
import RenderCheckBox from '^/form-helpers/render-checkbox';
import RenderDateField from '^/form-helpers/render-date-field';
import RenderDropDown from '^/form-helpers/render-dropdown';
import RenderInputField from '^/form-helpers/render-input-field';
import { formatFeeAmount, getSelectedPlan } from '^/plans/helpers';
import { StoreState } from '^/types';
import { PUBLIC_GET_PRACTICE_PLANS } from '../actions';

export type PlanProps = ConnectedProps<typeof connector>;

export interface PlanState {
  subscriptionFee: number | null | undefined;
}

class Plan extends React.Component<PlanProps, PlanState> {
  private initialDateValue: Date;

  constructor(props: PlanProps) {
    super(props);
    const hasReachedThreshold =
      moment()
        .toDate()
        .getDate() >= 20;
    this.initialDateValue = moment()
      .add(hasReachedThreshold ? 2 : 1, 'M')
      .startOf('M')
      .toDate();

    this.state = {
      subscriptionFee: null,
    };
  }

  public componentDidUpdate(prevProps: PlanProps): void {
    const { plans, selectedPlanId } = this.props;

    if (selectedPlanId !== prevProps.selectedPlanId) {
      const selectedPlan = getSelectedPlan(plans, selectedPlanId);
      this.setState({
        subscriptionFee: selectedPlan?.subscription_fee,
      });
    }
  }

  public getOptions = () => {
    const { plans, presetSignUp, presetPlan } = this.props;

    if (presetSignUp && presetPlan) {
      return [
        {
          label: presetPlan.description,
          value: presetPlan.id,
        },
      ];
    } else {
      const options = plans?.map(p => ({
        label: p.description,
        value: p.id,
      }));
      if (options) {
        return [{ label: 'please choose...', value: '' }].concat(options);
      }
    }
  };

  public render() {
    const { loading, plans } = this.props;
    const { subscriptionFee } = this.state;

    return (
      <PageSection
        className="patient-signup-section"
        heading="Plan Details"
        subHeading="If you are unsure which plan is suitable for you, please contact the
      practice"
        subHeadingClass="text-caption-light"
      >
        {!loading && plans && (
          <FormGroup>
            <Field
              className="required"
              label="Plan Name"
              name="plan"
              component={RenderDropDown}
              options={!loading && this.getOptions()}
              type="text"
              validate={required}
            />
          </FormGroup>
        )}
        {subscriptionFee !== null && (
          <div className="confirm-account-holder">
            <p className="margin-top-small">
              Subscription fee: {formatFeeAmount(subscriptionFee)}
            </p>
            <p className="text-caption-light margin-top-small">
              This is the current monthly price for 1 person on the plan. The
              monthly subscription may vary dependant on number of individuals
              on the plan, if discount is given and if there are different
              prices dependant on age ranges for children within the Adult price
              plan.
            </p>
          </div>
        )}
        <FormGroup>
          <Field
            lockDate
            className="required"
            showMonthYearPicker
            label="First Payment Date"
            name="start_date"
            component={RenderDateField}
            clearable={false}
            validate={required}
            type="date"
            pickerProps={{
              minDate: this.initialDateValue,
            }}
          />
        </FormGroup>
        <FormGroup className="text-caption-light margin-top-small margin-bottom-small">
          If todays date is on or beyond the 20th of the current month, please
          choose your start date as two months ahead e.g Today is the 26th of
          March, so the earliest month you should select as the start date is
          May.
        </FormGroup>
        <FormGroup>
          <Field label="Backdate" name="backdate" component={RenderCheckBox} />
          <Field
            component={RenderInputField}
            label="Backdate (Months)"
            name="backdate_months"
            type="number"
            validate={[isBackdateValid]}
          />
        </FormGroup>
        <FormGroup className="text-caption-light margin-top-small margin-bottom-small">
          The option to backdate allows you to make adjustments to your first
          iPlan payment. If you're unsure whether this applies to you, please
          reach out to your practice for further assistance and clarification.
        </FormGroup>

        <ErrorRenderer fields={['non_field_errors']} showStatusErrors />
      </PageSection>
    );
  }
}

export { Plan as TestablePlan };

const selector = formValueSelector('publicPatientSignupForm');

export const mapState = (state: StoreState) => ({
  selectedPlanId: selector(state, 'plan'),
  plans: state.publicPatient?.signupForm?.plans,
  loading: isPending(state.responses, PUBLIC_GET_PRACTICE_PLANS),
  presetPlan: state.publicPatient?.presetPlan,
  presetSignUp: state.publicPatient?.presetSignUp,
});

const connector = connect(mapState);

export default connector(Plan);
