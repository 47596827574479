import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';

import AppButton from '^/common/app-button';
import { patchPlan, reorderPlan } from '^/plans/actions';
import { Plan } from '^/plans/types';
import EditNicknameForm from './edit-nickname-form';

interface Props {
  practiceId: string;
  plan: Plan;
  plansCount: number;
}

interface State {
  isEditingNickname: boolean;
}

export type ReorderPlanProps = ConnectedProps<typeof connector> & Props;

class ReorderPlan extends React.PureComponent<ReorderPlanProps, State> {
  public readonly state: State = {
    isEditingNickname: false,
  };

  public toggleEditNickname = () => {
    this.setState({ isEditingNickname: !this.state.isEditingNickname });
  };

  public updateNickname = ({ nickname }: Partial<Plan>) => {
    const { practiceId } = this.props;

    this.props.patchPlan(practiceId, this.props.plan.id, {
      nickname,
    });
    this.toggleEditNickname();
  };

  public moveItem = (planId: string, position: number) => {
    const { practiceId } = this.props;

    this.props.reorderPlan(practiceId, planId, {
      position,
    });
  };

  public render() {
    const { plansCount, plan } = this.props;
    const { isEditingNickname } = this.state;

    return (
      <tr key={plan.id}>
        <td>
          <button
            disabled={plan.position === 0}
            onClick={this.moveItem.bind(this, plan.id, plan.position - 1)}
          >
            <FontAwesomeIcon icon="chevron-up" />
          </button>
        </td>
        <td>
          <button
            disabled={plan.position === plansCount - 1}
            onClick={this.moveItem.bind(this, plan.id, plan.position + 1)}
          >
            <FontAwesomeIcon icon="chevron-down" />
          </button>
        </td>
        <td>{plan.position}</td>
        <td>{plan.code}</td>
        <td>{plan.description}</td>
        {isEditingNickname ? (
          <td>
            <EditNicknameForm
              key={plan.id}
              form={`editNickname_${plan.id}`}
              onSubmit={this.updateNickname}
              onCancel={this.toggleEditNickname}
              initialValues={{ nickname: plan.nickname }}
            />
          </td>
        ) : (
          <>
            <td>{plan.nickname || '-'}</td>
            <td>
              <AppButton link onClick={this.toggleEditNickname}>
                {plan.nickname ? 'edit' : 'add'}
              </AppButton>
            </td>
          </>
        )}
      </tr>
    );
  }
}

export { ReorderPlan as TestableReorderPlan };

const connector = connect(undefined, { reorderPlan, patchPlan });

export default connector(ReorderPlan);
