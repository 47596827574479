import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import FormCustomisation from '^/common/form-setup/form-customisation';
import ReorderClinicians from '^/common/form-setup/reorder-clinicians';
import ReorderPlans from '^/common/form-setup/reorder-plans';
import { StoreState } from '^/types';

export type FormCustomisationOptionsTabFormProps = RouteComponentProps<{
  contactId: string;
}>;

export const mapState = (state: StoreState) => ({
  contactsCache: state.contactsCache,
});

const connector = connect(mapState, {});

class FormCustomisationOptionsTab extends React.PureComponent<
  ConnectedProps<typeof connector> & FormCustomisationOptionsTabFormProps
> {
  public render() {
    const {
      contactsCache,
      match: {
        params: { contactId },
      },
    } = this.props;

    const practice = contactsCache?.[contactId];

    if (!practice) {
      return null;
    }

    return (
      <>
        <FormCustomisation contactId={contactId} practice={practice} />
        <ReorderClinicians practiceId={contactId} />
        <ReorderPlans practiceId={contactId} />
      </>
    );
  }
}

export { FormCustomisationOptionsTab as TestableFormCustomisationOptionsTab };

export default connector(FormCustomisationOptionsTab);
